#WorkoutEditPage_Content {
  .list {
    .normal-row {
      &.sort-mode {
        border-left: none;
      }
      .main-cell {
        flex: 3;
      }
      .middle-cell {
        flex: 1;
      }
      .right-cell {
        flex: 1;
        text-align: right;
      }
    }

    .group-row {
      .balance {
        line-height: 0.7rem;
        position: relative;
        top: 6px;
        float: right;
        right: 6px;

        &.positive {
          color: rgba(var(--ion-color-success-rgb), 1);
        }
        &.negative {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }
      }
    }
  }
}
