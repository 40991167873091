[popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
#SessionEditPage_Content {
  .list {
    .normal-row {
      padding-left: 12px;

      &.sort-mode {
        border-left: none;
      }
      .main-cell {
        flex: 3;
        display: flex;

        .exercise-name {
          font-weight: bold;
          flex: 1;
        }
        .exercise-description {
          margin-left: 8px;
          flex: 1;
        }
        .rest {
          margin-left: 8px;
          flex: 1;
        }
      }
      .middle-cell {
        flex: 1;
      }
      .reps-cell {
        ion-input {
          cursor: pointer;
          width: 60px;
        }
        input {
          cursor: pointer;
          text-align: right;
        }
      }
      .rest-cell {
        min-width: 22px;
        .seconds {
          line-height: 0.5rem;
          font-size: 0.7rem;
        }
      }
      .right-cell {
        cursor: pointer;
        ion-input {
          cursor: pointer;
          width: 60px;
        }
        input {
          cursor: pointer;
          text-align: right;
        }
      }
    }

    .group-row {
      .exercise-description {
        line-height: 0.7rem;
        position: relative;
        top: 6px;
        float: right;
        right: 6px;
      }
    }
  }
}
