#RemindersListPage_Content {
  .list {
    .normal-row {
      display: flex;
      flex-direction: row;

      .payee {
        flex: 1;
      }

      div.auto-paid {
        text-align: right;
        margin-right: 10px;
      }

      div.badges {
        text-align: right;
        margin-right: 5px;
        padding-top: 2px;
        flex: 0.08;

        ion-badge {
          margin-right: 5px;
        }
      }

      .rem-notes {
        color: #888;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: hidden;
        font-size: 0.8rem !important;

        &.no-notes {
          display: none;
        }
      }

      .date {
        flex: 0.08;
        div {
          min-width: 50px;
          text-align: center;
          font-weight: bold;
        }
      }

      .numbers {
        flex: 0.1;
        text-align: right;
        min-width: 55px;
        white-space: nowrap;
        &.positive {
          color: rgba(var(--ion-color-success-rgb), 1);
        }
        &.negative {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
        }
      }
    }
  }
}
