html.plt-ios.plt-hybrid {
  #SessionEditPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #SessionEditPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#SessionEditMenu {
  cursor: pointer;
}


