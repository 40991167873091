#SessionsListPage_Content {
  .list {
    #SessionRow {
      display: block;
      padding: 4px;
      margin-left: 16px;
      width: calc(100% - 24px) !important;
      font-size: 0.9rem;
      cursor: pointer;
      border-radius: 6px;
      display: flex;
      flex-direction: row;

      &.odd-row {
        background-color: var(--ion-color-secondary);
      }

      .grow {
        flex-grow: 1;
      }

      .cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.icon {
          margin-right: 8px;
        }
      }

      .bottom-row {
        display: flex;
        flex-direction: row;
        .grow {
          flex-grow: 1;
        }
      }

      .notes,
      .status {
        display: block;
        color: #888;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: hidden;
        font-size: 0.8rem !important;

        &.no-notes {
          display: none;
        }
      }
    }
  }
}
